<template>
  <div class="csn-user-menu">
    <div class="dropdown">
      <a
        id="dropdownMenuButton"
        class="nav-link casino-btn btn casino-btn-default dropdown-toggle casino-btn-user-menu csn-nav-menu-dropdown csn-nav-btn"
        type="button"
        data-toggle="dropdown"
      >
        <span
          class="casino-icon casino-icon-profile csn-nav-btn-icon csn-nav-btn-icon-profile"
        ></span>
      </a>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <div class="menu-box">
          <header>
            <Route
              :to="{ name: route.deposit }"
              class="btn casino-btn casino-btn-default"
            >
              {{ t('deposit') }}
            </Route>
            <Route
              class="btn casino-btn casino-btn-default"
              :to="{ name: route.withdraw }"
            >
              {{ t('withdraw') }}
            </Route>
          </header>
          <div class="menu-list">
            <ul>
              <li>
                <Route :to="{ name: route.userProfile }">
                  <span class="casino-icon casino-icon-profile"></span>
                  {{ t('my_account') }}
                </Route>
              </li>
              <li>
                <Route :to="{ name: route.userTransactions }">
                  <span class="casino-icon casino-icon-transactions"></span>
                  {{ t('transactions') }}
                </Route>
              </li>
              <li>
                <Route :to="{ name: route.userGameRounds }">
                  <span class="casino-icon casino-icon-game-rounds"></span>
                  {{ t('game_rounds') }}
                </Route>
              </li>
              <li>
                <Route>
                  <span class="casino-icon casino-icon-chat-support"></span>
                  {{ t('support') }}
                </Route>
              </li>
              <li>
                <Route>
                  <span class="casino-icon casino-icon-information"></span>
                  {{ t('information') }}
                </Route>
              </li>
              <li>
                <a @click="logout">
                  <span class="casino-icon casino-icon-logout"></span>
                  {{ t('logout') }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { USER_MENU, RouteName, Module, LACKS_INFO } from '@/constants'
import { toPathCase, logout } from '@/helpers'

export default {
  name: USER_MENU,
  components: {
    Route: () => import('@/components/Route'),
  },
  computed: {
    ...mapGetters({ lacksUserInfo: toPathCase([Module.USER, LACKS_INFO]) }),
    t() {
      return this.$createComponentTranslator(USER_MENU)
    },
    route() {
      return {
        deposit: RouteName.DEPOSIT,
        homepage: RouteName.HOME,
        userGameRounds: RouteName.USER_GAME_ROUNDS,
        userProfile: this.lacksUserInfo
          ? RouteName.USER_EDIT
          : RouteName.USER_ACCOUNT,
        userTransactions: RouteName.USER_TRANSACTIONS,
        withdraw: RouteName.WITHDRAWAL,
      }
    },
  },
  methods: {
    async logout() {
      await logout({ nextRoute: { name: RouteName.HOME } })
    },
  },
}
</script>
